import { Button } from "@material-ui/core";
import { isNil, omitBy } from "lodash";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import * as queryString from "query-string";
import { IMessageIDS, t } from "../../../../i18n/util";
import { API, isUnauthorizedError } from "../../../../network/API";
import { generalStore } from "../../../../stores/GeneralStore";
import { pgxAdminStore } from "../../../../stores/PgxAdminStore";
import { IAssignedPatient, IDoctor, IPatient } from "../../../../types";
import { StepDialog } from "../../../dialogs/StepDialog";
import { getDoctorFormPages, IDoctorValues } from "../../../forms/DoctorForm";
import {
    getPatientFormPages,
    IInitialPatientValues,
    IInternalPatientValues,
    IPatientValues,
} from "../../../forms/PatientForm";
import { CustomPagination } from "../../../ui/CustomPagination";
import { Searchbar } from "../../../ui/Searchbar";
import { useDebouncedState } from "../../../util/useDebouncedState";
import { useTableSort } from "../../../util/useTableSort";
import { DoctorsTable } from "./DoctorsTable";
import { EmptyHint } from "./EmptyHint";
import { PatientsTable } from "./PatientsTable";
import { useLocation } from "react-router";
import { fetchFile } from "../../../util/fetchFile";
import { ConfirmDialog } from "../../../ui/ConfirmDialog";
import { getPatientLink } from "../../../util/Patient";
import { Routes } from "../../../routers/Routes";
import { Colors } from "../../../util/Colors";
import { ReleaseReportConfirmDialog } from "../../../dialogs/ReleaseReportConfirmDialog";

const ActionContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
`;

const ActionButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-left: 40px;
`;

const DescriptionListElement = styled.li`
    margin-bottom: 15px;
`;

const PatientListElement = styled.li`
    display: flex;
    justify-content: space-between;
`;

const PatientAnchor = styled.a`
    color: ${Colors.primary};
    margin-bottom: 5px;
`;

type IProps = {
    site: "patients" | "doctors";
};

const ROWS_PER_PAGE = 10;

export const UserManagement = observer(({ site }: IProps) => {
    const location = useLocation();
    const searchQuery = queryString.parse(location.search).search;
    const [showAddEditUserDialog, setShowAddEditUserDialog] = React.useState(false);
    const [debouncedSearchKeyword, searchKeyword, setSearchKeyword] = useDebouncedState(
        typeof searchQuery === "string" ? searchQuery : "",
        500,
    );
    const [searchTotalRows, setSearchTotalRows] = React.useState<number | null>(null);
    const [rows, setRows] = React.useState<IDoctor[] | IPatient[]>([]);
    const [totalRows, setTotalRows] = React.useState<number | null>(null);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [lastUpdated, setLastUpdated] = React.useState(Date.now());
    const [editUser, setEditUser] = React.useState<{ uid: string; user: IDoctorValues | IInitialPatientValues } | null>(
        null,
    );
    const [isDeletePatientDialogOpen, setIsDeletePatientDialogOpen] = React.useState(false);
    const [confirmDialogText, setConfirmDialogText] = React.useState<string | React.ReactElement>();
    const [isConfirmModalDisabled, setIsConfirmModalDisabled] = React.useState(false);
    const { orderBy, orderDirection, onChangeSort } = useTableSort("lastname");
    const [showReleaseReportDialog, setShowReleaseReportDialog] = React.useState(false);
    const [selectedPatient, setSelectedPatient] = React.useState<IPatient | null>(null);

    const handleClickNewUser = () => {
        setShowAddEditUserDialog(true);
    };

    const handleClickEditUser = (user: IDoctorValues | IInitialPatientValues, uid: string) => {
        setEditUser({ uid, user });
        setShowAddEditUserDialog(true);
    };

    const handleCloseAddEditUserDialog = () => {
        setEditUser(null);
        setShowAddEditUserDialog(false);
    };

    React.useEffect(() => {
        if (site === "patients") {
            pgxAdminStore.fetchDoctors();
        }
    }, [site]);

    // Used to track previous value
    const prevKeywordRef = React.useRef(searchKeyword);

    React.useEffect(() => {
        const loadUsers = async () => {
            try {
                generalStore.isLoading = false;

                if (prevKeywordRef.current !== debouncedSearchKeyword) {
                    // set new previous value
                    prevKeywordRef.current = debouncedSearchKeyword;
                    if (currentPage !== 1) {
                        // force first page and return, the effect will pick up the changed page and rerun
                        setCurrentPage(1);
                        return;
                    }
                }

                const response = await API[site === "doctors" ? "getDoctors" : "getPatients"]({
                    limit: ROWS_PER_PAGE,
                    offset: (currentPage - 1) * ROWS_PER_PAGE,
                    order: orderDirection,
                    orderBy,
                    ...(debouncedSearchKeyword ? { keyword: debouncedSearchKeyword } : {}),
                });

                if (response) {
                    if (!debouncedSearchKeyword) {
                        setTotalRows(response.total);
                    }

                    setRows(response.results);
                    setSearchTotalRows(response.total);
                }
            } catch (error) {
                if (!isUnauthorizedError(error)) {
                    generalStore.errorMessage =
                        site === "doctors" ? t("error.loadDoctorsList") : t("error.loadPatientsList");
                }
                console.error(error);
            } finally {
                generalStore.isLoading = false;
            }
        };
        loadUsers();
    }, [currentPage, debouncedSearchKeyword, orderBy, orderDirection, site, lastUpdated]);

    const handleSubmitDoctor = async (model: IDoctorValues) => {
        generalStore.isLoading = true;

        try {
            // TODO success handling
            const response = editUser ? await API.editDoctor(editUser.uid, model) : await API.addDoctor(model);

            if (response) {
                setLastUpdated(Date.now());
            }
        } catch (error) {
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = error.statusText;
            }
            console.error(error);
        }

        generalStore.isLoading = false;
        handleCloseAddEditUserDialog();
    };

    const editPatient = async (uid: string, patient: IInitialPatientValues, options: Partial<IPatientValues>) => {
        const doctorUids = options.doctorUid;
        delete options.doctorUid;

        try {
            await API.editPatient(uid, options);

            if (doctorUids) {
                const uidsToDelete = getDoctorsUidsToDelete(patient.doctorUid, doctorUids);

                await API.assignDoctorsToPatient(uid, doctorUids);

                if (uidsToDelete.length) {
                    await API.deleteDoctorFromPatient(uid, uidsToDelete);
                }

                setLastUpdated(Date.now());
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getDoctorsUidsToDelete = (currentUids: string[], newUids: string[]) => {
        const uidsToDelete = currentUids.filter(currentUid => !newUids.includes(currentUid));

        return uidsToDelete;
    };

    const handleSubmitPatient = async (model: IInternalPatientValues) => {
        generalStore.isLoading = true;

        try {
            // TODO success handling
            const mappedModel = {
                ...model,
                birthdate: model.birthdate ? model.birthdate.format("YYYY-MM-DD") : null,
            };

            const response = editUser
                ? await editPatient(editUser.uid, editUser.user as IInitialPatientValues, omitBy(mappedModel, isNil))
                : await API.addPatient(mappedModel);

            if (response) {
                setLastUpdated(Date.now());
            }
        } catch (error) {
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = error.statusText;
            }
            console.error(error);
        }

        generalStore.isLoading = false;
        handleCloseAddEditUserDialog();
    };

    const handleResendEmail = async (uid: string) => {
        try {
            await API.resendEmail({ uid });
            generalStore.successMessage = t("success.resendEmail");
        } catch (error) {
            generalStore.errorMessage = t("error.resendEmail");
        }
    };

    const handleClickCsvExport = async () => {
        try {
            const csv = await API[site === "doctors" ? "getDoctorsCsv" : "getPatientsCsv"]({
                order: orderDirection,
                orderBy,
                ...(debouncedSearchKeyword ? { keyword: debouncedSearchKeyword } : {}),
            });

            fetchFile(csv, true, `${site}.csv`);
        } catch (error) {
            generalStore.errorMessage = t("error.csv_export");
        }
    };

    const handleSubmitReleaseReport = async (uid: string, reportReleased: boolean) => {
        try {
            const response = await API.releaseReport(uid, reportReleased);

            if (response) {
                setLastUpdated(Date.now());
                setShowReleaseReportDialog(false);
            }
        } catch (error) {
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = t("error.releaseReport");
            }
            console.error(error);
        }
    };

    const handleClickReleaseReport = async (patient: IPatient) => {
        setSelectedPatient(patient);
        setShowReleaseReportDialog(true);
    };

    const handleCloseReleaseReportDialog = () => {
        setSelectedPatient(null);
        setShowReleaseReportDialog(false);
    };

    const handleClickDeleteUser = async (user: IDoctorValues | IInitialPatientValues, uid: string) => {
        await loadConfirmDialogContent(uid);
        setEditUser({ uid, user });
        setIsDeletePatientDialogOpen(true);
    };

    const handleCloseDeletePatientDialog = () => {
        setIsConfirmModalDisabled(false);
        setConfirmDialogText(undefined);
        setIsDeletePatientDialogOpen(false);
    };

    const handleConfirmDeleteUser = async () => {
        try {
            if (editUser && editUser.uid) {
                const response =
                    site === "patients" ? await API.deletePatient(editUser.uid) : await API.deleteDoctor(editUser.uid);

                if (response.success) {
                    setLastUpdated(Date.now());
                    setIsDeletePatientDialogOpen(false);
                    setEditUser(null);
                }
            }
        } catch (error) {
            setIsDeletePatientDialogOpen(false);
            setEditUser(null);

            if (error.statusCode === 409 && site === "doctors") {
                generalStore.errorMessage = t("screen.pgx_admin.confirm_dialog.issuedSummaryReport");
            } else {
                generalStore.errorMessage = t("common.unexpectedError");
            }
            console.log(error);
        }
    };

    const getConfirmDialogHeadline = () => {
        return site === "patients"
            ? t("screen.pgx_admin.confirm_dialog.headline.patient")
            : t("screen.pgx_admin.confirm_dialog.headline.doctor");
    };

    const getPatientsWithOneDoctorAssigned = (patients: IAssignedPatient[]) => {
        const patientsWithOneDoctorAssigned: IAssignedPatient[] = [];

        patients.forEach(patient => {
            if (patient.doctors.length === 1) {
                patientsWithOneDoctorAssigned.push(patient);
            }
        });

        return patientsWithOneDoctorAssigned;
    };

    const loadConfirmDialogContent = React.useCallback(
        async (uid: string) => {
            if (site === "patients") setConfirmDialogText(t("screen.pgx_admin.confirm_dialog.description.patient"));

            if (uid && site === "doctors") {
                try {
                    generalStore.isLoading = true;
                    const doctorData = await API.getDoctor(uid);
                    const assignedPatientsWithOneDoc = getPatientsWithOneDoctorAssigned(doctorData.patients);
                    if (assignedPatientsWithOneDoc.length > 0) {
                        setIsConfirmModalDisabled(true);
                        setConfirmDialogText(getPatientList(doctorData.patients));
                    } else {
                        setConfirmDialogText(t("screen.pgx_admin.confirm_dialog.description.doctor"));
                    }
                    generalStore.isLoading = false;
                } catch (error) {
                    generalStore.isLoading = false;
                }
            }
        },
        [site],
    );

    const getPatientList = (patients: IAssignedPatient[]) => {
        const listElements = patients.map(patient => {
            return (
                <PatientListElement key={patient.uid}>
                    {patient.firstname} {patient.lastname}{" "}
                    <PatientAnchor href={getPatientLink(Routes.PGX_ADMIN.PATIENTS, patient.pharmgenetixId)}>
                        {t("screen.pgx_admin.confirm_dialog.description.link_to_patient_text")}
                    </PatientAnchor>
                </PatientListElement>
            );
        });

        return (
            <ul>
                <DescriptionListElement>
                    <strong>{t("screen.pgx_admin.confirm_dialog.description.reassign_patients")}:</strong>
                </DescriptionListElement>
                {listElements}
            </ul>
        );
    };

    const enableMfaForUser = (uid: string) => async () => {
        try {
            await API.patchUser({ uid, mfaEnabled: true });
            setLastUpdated(Date.now());
        } catch {
            generalStore.errorMessage = t("common.unexpectedError");
        }
    };

    const disableMfaForUser = (uid: string) => async () => {
        try {
            await API.patchUser({ uid, mfaEnabled: false });
            setLastUpdated(Date.now());
        } catch {
            generalStore.errorMessage = t("common.unexpectedError");
        }
    };

    const tableProps = {
        onClickEditUser: handleClickEditUser,
        onClickDeleteUser: handleClickDeleteUser,
        onClickResendEmail: handleResendEmail,
        onClickReleaseReport: handleClickReleaseReport,
        onChangeSort,
        orderBy,
        orderDirection,
        enableMfaForUser,
        disableMfaForUser,
    };

    const form = {
        doctors: getDoctorFormPages(editUser?.user as IDoctorValues),
        patients: getPatientFormPages(editUser?.user as IInitialPatientValues),
    };

    return totalRows === null && searchTotalRows === null ? null : (
        <>
            <ConfirmDialog
                isOpen={isDeletePatientDialogOpen}
                headline={getConfirmDialogHeadline()}
                text={confirmDialogText}
                isDisabled={isConfirmModalDisabled}
                okButtonText={
                    site === "patients" ? t("table.action.delete_patient.text") : t("table.action.delete_doctor.text")
                }
                action={handleConfirmDeleteUser}
                onClose={handleCloseDeletePatientDialog}
            />
            <ActionContainer>
                <Searchbar
                    data-id="user_search"
                    onChange={setSearchKeyword}
                    placeholder={t(`screen.pgx_admin.${site}.actions.searchbar.placeholder` as IMessageIDS)}
                    value={searchKeyword}
                    style={{ flexGrow: 1, flexBasis: 0 }}
                />
                <ActionButtonContainer style={{ flexGrow: 1, flexBasis: 0 }}>
                    <Button
                        data-id="csv_export"
                        color="primary"
                        onClick={handleClickCsvExport}
                        style={{
                            minWidth: 150,
                        }}
                        href=""
                    >
                        {t("screen.pgx_admin.csv_export.button.label")}
                    </Button>
                    <Button
                        data-id="new_user_button_1"
                        variant="contained"
                        color="primary"
                        onClick={handleClickNewUser}
                        style={{
                            minWidth: 240,
                            marginLeft: 16,
                        }}
                    >
                        {t(`screen.pgx_admin.${site}.actions.add.button.label` as IMessageIDS)}
                    </Button>
                </ActionButtonContainer>
            </ActionContainer>
            {site === "patients" && <PatientsTable {...tableProps} rows={rows as IPatient[]} />}
            {site === "doctors" && <DoctorsTable {...tableProps} rows={rows as IDoctor[]} />}
            {totalRows !== null && searchTotalRows !== null && totalRows > 0 && (
                <CustomPagination
                    data-id="pagination"
                    count={searchTotalRows}
                    rowsPerPage={ROWS_PER_PAGE}
                    page={currentPage}
                    onChangePage={setCurrentPage}
                />
            )}
            {totalRows === 0 && <EmptyHint site={site} onClickNewUser={handleClickNewUser} />}
            {site === "doctors" && showAddEditUserDialog && (
                <StepDialog
                    data-id="add_edit_doctor_dialog"
                    pages={form[site].pages}
                    initialValues={form[site].initialValues}
                    open={showAddEditUserDialog}
                    onClose={handleCloseAddEditUserDialog}
                    onSubmit={handleSubmitDoctor}
                />
            )}
            {site === "patients" && showAddEditUserDialog && (
                <StepDialog
                    data-id="add_edit_patient_dialog"
                    pages={form[site].pages}
                    initialValues={form[site].initialValues}
                    open={showAddEditUserDialog}
                    onClose={handleCloseAddEditUserDialog}
                    onSubmit={handleSubmitPatient}
                />
            )}
            {selectedPatient && showReleaseReportDialog && (
                <ReleaseReportConfirmDialog
                    open={showReleaseReportDialog}
                    uid={selectedPatient.uid}
                    reportReleased={selectedPatient.reportReleased}
                    fullName={`${selectedPatient.lastname} ${selectedPatient.firstname}`}
                    birthdate={selectedPatient.birthdate}
                    onClose={handleCloseReleaseReportDialog}
                    onSubmit={handleSubmitReleaseReport}
                />
            )}
        </>
    );
});
