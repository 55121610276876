import { action, observable } from "mobx";
import { t } from "../i18n/util";
import { API, isUnauthorizedError } from "../network/API";
import {
    IMedication,
    IPatient,
    ISelectedGroups,
    ISubstance,
    ISubstanceId,
    IOptimizerMedication,
    ITableParams,
} from "../types";
import { generalStore } from "./GeneralStore";
import { create, persist } from "mobx-persist";

const initPatientTable: ITableParams = {
    currentPage: 1,
    debouncedSearchKeyword: "",
    orderBy: "lastname",
    orderDirection: "asc",
};
class DoctorStore {
    @persist @observable showAppWarningDialog = true;
    @persist @observable rowsPerPage = 10;
    @observable isRehydrated = false;

    @observable selectedPatient: IPatient | null = null;
    @observable currentMedication: IMedication | null = null;
    @observable replacementMedication: IMedication | null = null;
    @observable fixedSubstances: ISubstanceId[] = [];
    @observable remarks = "";
    @observable manualReplaceSubstance: ISubstance | null = null;
    @observable selectedGroups: ISelectedGroups = {};
    @observable optimizerResultSameLevel: IOptimizerMedication[] = [];
    @observable patientTable: ITableParams = initPatientTable;

    @action reset() {
        this.selectedPatient = null;
        this.currentMedication = null;
        this.replacementMedication = null;
        this.fixedSubstances = [];
        this.remarks = "";
        this.manualReplaceSubstance = null;
        this.selectedGroups = {};
        this.optimizerResultSameLevel = [];
    }

    @action wipe() {
        this.reset();
        this.patientTable = initPatientTable;
    }

    @action loadPatient = async (uid: string) => {
        try {
            const patient = await API.getPatient(uid);

            if (patient) {
                doctorStore.selectedPatient = patient;
            }
        } catch (error) {
            if (!isUnauthorizedError(error)) {
                generalStore.errorMessage = t("error.loadPatient");
            }
            console.error(error);
        }
    };
}

const hydrate = create({
    storage: require("localforage"),
});

const doctorStore = new DoctorStore();

hydrate("doctor", doctorStore)
    .then(() => {
        doctorStore.isRehydrated = true;
    })
    .catch(error => {
        console.error(error);
    });

export { doctorStore };
