import { createMuiTheme } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Styles } from "./Styles";
import { Colors } from "./Colors";

const buttonBase: CSSProperties = {
    height: 42,
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none",
};

const containedButtonBaseColors: CSSProperties = {
    color: "#ffffff !important",
    backgroundColor: Styles.MAIN_COLOR,
    "&:hover": {
        backgroundColor: Styles.MAIN_COLOR_HOVERED,
    },
    "&:disabled": {
        backgroundColor: Styles.MAIN_COLOR_DISABLED,
    },
};

export const theme = createMuiTheme({
    props: {
        MuiButton: {
            disableElevation: true,
        },
    },
    typography: {
        fontFamily: "Karla, sans-serif",
        fontSize: 16,
        button: {
            fontFamily: "Maven Pro",
        },
    },
    palette: {
        primary: {
            main: Styles.MAIN_COLOR,
        },
        secondary: {
            main: Styles.MAIN_COLOR,
        },
        error: {
            main: Colors.danger,
        },
        tonalOffset: 0.1,
    },
    mixins: {
        toolbar: {
            minHeight: 70,
            "@media (min-width:600px)": {
                paddingLeft: 40,
                paddingRight: 40,
            },
        },
    },
    overrides: {
        MuiAppBar: {
            root: {
                backgroundColor: "#ffffff",
                borderBottom: "1px solid #d8d8d8",
                boxShadow: "0 8px 10px 0 rgba(101, 121, 222, 0.05)",
            },
        },
        MuiDialog: {
            paper: {
                padding: 40,
            },
        },
        MuiDialogTitle: {
            root: {
                padding: 0,
                paddingBottom: 24,
            },
        },
        MuiDialogContent: {
            root: {
                padding: 0,
            },
        },
        MuiDialogActions: {
            root: {
                padding: 0,
                paddingTop: 32,
            },
        },
        MuiToolbar: {
            gutters: {
                paddingLeft: 40,
                paddingRight: 40,
            },
            dense: {
                minHeight: 24,
            },
        },
        MuiTabs: {
            indicator: {
                height: 4,
            },
        },
        MuiTableCell: {
            root: {
                padding: "0px 16px",
                borderBottom: "2px solid #E1E1E1",
                height: 58,
            },
            head: {
                fontWeight: "bold",
                color: "#A1A1A1",
                letterSpacing: 0.5,
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: "15px 12px 14px 12px",
            },
            multiline: {
                padding: "15px 12px",
            },
        },
        MuiInputLabel: {
            outlined: {
                transform: "translate(12px, 17px) scale(1)",
            },
        },
        MuiButton: {
            sizeSmall: {
                height: 24,
                fontSize: 14,
                padding: "0px 5px",
            },
            sizeLarge: {
                height: 56,
                fontSize: 18,
            },
            containedPrimary: {
                ...buttonBase,
                ...containedButtonBaseColors,
            },
            containedSecondary: {
                ...buttonBase,
                ...containedButtonBaseColors,
            },
            text: {
                ...buttonBase,
                color: Styles.MAIN_COLOR,
                transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                "&:hover": {
                    color: Styles.MAIN_COLOR_HOVERED,
                    backgroundColor: "transparent",
                },
                "&:disabled": {
                    color: Styles.MAIN_COLOR_DISABLED,
                },
            },
        },
        MuiSnackbarContent: {
            root: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 18,
            },
        },
    },
});
